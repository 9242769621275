<template>
  <div class="home">
    <section id="homehead">
      <h1 class="maintitle">PLAN<br>DE SITE</h1>      
      <p class="maintext"></p>
      </section>
    <section class="legals" id="anchor">
      <h2 class="legals__title">PLAN DE SITE</h2>
      <ul>
        <li class="legals__list"><router-link to="Home">Accueil</router-link></li>
        <li class="legals__list"><router-link to="Services">Services</router-link></li>
            <ul>
            <li class="legals__list__under"><router-link to="Sitesweb">Sites web</router-link></li>
            <li class="legals__list__under"><router-link to="Videos">Vidéos</router-link></li>
            <li class="legals__list__under"><router-link to="Creationcontenu">Création de contenu</router-link></li>
          </ul>
        <li class="legals__list"><router-link to="Portfolio">Portfolio</router-link></li>
        <li class="legals__list"><router-link to="Tarifs">Tarifs</router-link></li>
        <li class="legals__list"><router-link to="Contact">Contact</router-link></li>
        <li class="legals__list"><router-link to="CGUCGV">CGU et CGV</router-link></li>
        <li class="legals__list"><router-link to="Mentionslegales">Mentions Légales</router-link></li>
        <li class="legals__list"><router-link to="Privacy">Politique de confidentialité</router-link></li>
        <li class="legals__list"><router-link to="Sitemap">Plan du site</router-link></li>
      </ul>
    </section>
  </div>
</template>

<script>
import $ from 'jquery';

export default {
  name: 'Home',
  components: {
  },
  mounted() {
    /************** APPARITION AU SCROLL */
        var rafId = null;
        var delay = 200;
        var lTime = 0;

        function scroll() {
          var scrollTop = $(window).scrollTop();
          var height = $(window).height()
          var visibleTop = scrollTop + height;
          $('.revealleft').each(function() {
            var $t = $(this);
            if ($t.hasClass('reveal_visible')) { return; }
            var top = $t.offset().top;
            if (top <= visibleTop) {
              if (top + $t.height() < scrollTop) {
                $t.removeClass('reveal_pending').addClass('reveal_visible');
              } else {
                $t.addClass('reveal_pending');
                if (!rafId) requestAnimationFrame(reveal);  
              }
            }
          });
          $('.revealright').each(function() {
            var $t = $(this);
            if ($t.hasClass('reveal_visible')) { return; }
            var top = $t.offset().top;
            if (top <= visibleTop) {
              if (top + $t.height() < scrollTop) {
                $t.removeClass('reveal_pending').addClass('reveal_visible');
              } else {
                $t.addClass('reveal_pending');
                if (!rafId) requestAnimationFrame(reveal);  
              }
            }
          });
        }
        function reveal() {
          rafId = null;
          var now = performance.now();
          
          if (now - lTime > delay) {
            lTime = now;
            var $ts = $('.reveal_pending');
            $($ts.get(0)).removeClass('reveal_pending').addClass('reveal_visible');  
          }
          
          
          if ($('.reveal_pending').length >= 1) rafId = requestAnimationFrame(reveal);
        }

        $(scroll);
        $(window).scroll(scroll);
  }
}
</script>

<style lang="scss">

/*************** HAUT DE PAGE */
.maintitle {
  font-family: 'Yanone Kaffeesatz', sans-serif;
  font-size: 8vw;
  color: white;
  margin-bottom: 0;
  @media (max-width: 1440px) {
		font-size: 8vw;
	}
  @media (max-width: 1024px) {
		font-size: 10vw;
	}
  @media (max-width: 768px) {
		font-size: 10vw;
	}
  @media (max-width: 425px) {
		font-size: 10vw;
	}
  @media (max-width: 300px) {
		font-size: 10vw;
	}
}

#homehead {
  width: 80%;
  margin: auto;
  height: 73vh;
}

.maintext {
  font-family: 'Open Sans', sans-serif;
  color: white;
  width: 60%;
  border-top: 6px solid white;
  padding-top: 24px;
  @media (max-width: 1440px) {
		font-size: medium;
    width: 60%;
	}
  @media (max-width: 1024px) {
		font-size: medium;
    width: 80%;
	}
  @media (max-width: 768px) {
		font-size: small;
    width: 80%;
	}
  @media (max-width: 425px) {
		font-size: small;
    width: 80%;
	}
  @media (max-width: 300px) {
		font-size: small;
    width: 80%;
	}
}

/********************* MENTIONS */

hr {
  margin-top:8px;
  margin-bottom: 5px;
}

.legals {
  width: 70%;
  margin: auto;
  @media (max-width: 768px) {
    width: 90%;
	}
  &__title {
        font-family: 'Yanone Kaffeesatz', sans-serif;
        font-size: 40px;
        margin-top: 30px;
        margin-bottom: 10px;
        position: relative;
        width: 100%;
        text-align: left;
        &::before {
        content: '';
        width: 100%;
        position: absolute;
        left: 0;
        top: calc( 50% - 1.5px );
        border-bottom: 20px solid #38e01f;
        z-index: -1;
        }
  }
  &__subtitle {
    margin-top: 5px;
    margin-bottom: 5px;
  }
  &__list {
    margin-left: 5%;
    &__under {
      margin-left: 10%;
    }
  }
  &__link {
    width: fit-content;
    display: inline-block;
    &::after {
        display:block;
        content: '';
        border-bottom: solid 2px black;  
        transform: scaleX(0);  
        transition: transform 250ms ease-in-out;
    }
    &:hover::after {
      transform: scaleX(1);
    }
  }
}

/********* Apparition au scroll */

.revealleft {
  opacity: 0;
  transform: translate3d(-100px, 0, 0);
  transition: opacity 0.8s, transform 0.8s;
}

.revealright {
  opacity: 0;
  transform: translate3d(100px, 0, 0);
  transition: opacity 0.8s, transform 0.8s;
}

.reveal_visible {
    opacity: 1;
    transform: translate3d(0, 0, 0);
  }

</style>